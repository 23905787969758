<template>
  <div class="list">
    <global-table :tableField="tableField" :tableData="tableData"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { tableField } from './logdialog'
export default {
  components: Component.components,
  props: ['splitDeliverLogData'],
  name: 'logdialog',
  data () {
    return {
      tableField: tableField,
      tableData: []
    }
  },
  watch: {
    splitDeliverLogData (newVal) {
      this.tableData = newVal
    }
  }
}
</script>

<style scoped lang="scss">
.list{
  :deep(.tableList){
    padding: 0px;
  }
}
</style>
